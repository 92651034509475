/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCz-eLFcXyWaKs_1jpKrXaTgue4xY2xa-I",
  "appId": "1:713780772958:web:151d671ac708ff127174cf",
  "authDomain": "schooldog-i-dalton-ga.firebaseapp.com",
  "measurementId": "G-P1TKYW3W9Q",
  "messagingSenderId": "713780772958",
  "project": "schooldog-i-dalton-ga",
  "projectId": "schooldog-i-dalton-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-dalton-ga.appspot.com"
}
